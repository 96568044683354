document.addEventListener('DOMContentLoaded', function () {
    const stickyElements = document.querySelectorAll('.stick')

    const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
            const stickyElement = entry.target.previousElementSibling
            if (stickyElement && stickyElement.classList.contains('stick') && entry.intersectionRect.y > 0) {
                const opacity = 1 - entry.intersectionRatio
                stickyElement.style.opacity = opacity
                stickyElement.style.filter = 'blur(' + (10 - (opacity * 10)) + 'px)'

            }
        })
    }, {
        root: null, // observing intersections with respect to the viewport
        threshold: createThresholds() // create an array of thresholds for fine-grained observation
    })

    stickyElements.forEach(sticky => {
        const nextElement = sticky.nextElementSibling
        if (nextElement) {
            observer.observe(nextElement)
        }
    })

    // Adjust the opacity of the last .stick element on scroll
    window.addEventListener('scroll', function () {
        const lastSticky = stickyElements[stickyElements.length - 1]
        if (lastSticky && !lastSticky.nextElementSibling) {
            lastSticky.style.opacity = 1
            lastSticky.style.filter = 'blur(0px)'
        }
    })

    // Helper function to create an array of thresholds for the Intersection Observer
    function createThresholds () {
        let thresholds = []
        for (let i = 0; i <= 1.0; i += 0.01) {
            thresholds.push(i)
        }
        return thresholds
    }
})
