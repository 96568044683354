
/********** constants *********/

// color pairs array For colour cycle
const colorPairs = [
    {text: '0 4 43', background: '251 199 155'},
    {text: '251 199 155', background: '115 50 15'},
    {text: '251 199 155', background: '0 4 43'},
    {text: '251 199 155', background: '0 141 208'},
    {text: '251 199 155', background: '131 190 96'},
]

let currentColorIndex = 0
let isScrollingDown = null

const transitionDistance = window.innerHeight / 3

/***** end of constants *******/

function isInViewport (element) {
    const rect = element.getBoundingClientRect()
    return rect.bottom >= 0 && rect.bottom <= window.innerHeight
}

function calculateColorValue (start, end, percentage) {
    const startValues = start.split(' ').map(val => parseInt(val, 10))
    const endValues = end.split(' ').map(val => parseInt(val, 10))

    const result = startValues.map((startVal, index) => {
        return startVal + (endValues[index] - startVal) * percentage
    })

    return result.join(' ')
}

function getNextColorIndex ($currentIndex) {
    $currentIndex++
    if($currentIndex >= colorPairs.length) {
        $currentIndex = 1;
    }
    return $currentIndex;
}

function getTriggersToTheirPosition () {
    let triggers = [
        ...document.querySelectorAll('.trigger')
    ]
    let footerTrigger = document.querySelector('footer');

    // filter triggers that are too close to the top or bottom / footer position
    triggers = triggers.filter((trigger, index) => {
        const bcr = trigger.getBoundingClientRect()
        if (bcr.top + window.scrollY < window.innerHeight + 10) {
            return false;
        }
        if (bcr.top + transitionDistance > footerTrigger.getBoundingClientRect().top) {
            return false;
        }
        return true;
    })
    triggers.push(footerTrigger);

    let lastColorIndex = -1;
    triggers = triggers.map((trigger, index) => {
        const bcr = trigger.getBoundingClientRect()
        lastColorIndex = getNextColorIndex(lastColorIndex)
        const colorIndex = lastColorIndex
        const nextColorIndex = (index === triggers.length -1 ? 0 : getNextColorIndex(colorIndex))
        return {
            index,
            element: trigger,
            top: bcr.top,
            bottom: bcr.bottom,
            from: colorPairs[colorIndex],
            to: colorPairs[nextColorIndex],
        }
    })
    triggerCache = triggers
    return triggers;
}

function isBottomThirdOfScreen (trigger) {
    return trigger.top <= window.innerHeight && trigger.top > window.innerHeight - transitionDistance
}

function isAboveBottomThirdOfScreen (prev, current) {
    const aboveBottomThirdOfScreen = (el) => el.top < window.innerHeight - transitionDistance

    if (aboveBottomThirdOfScreen(current)) {
        if (prev) {
            return current.top > prev.top ? current : prev
        }
        return current
    }
    return prev
}

const footer = document.querySelector('footer')
const footerHeight = footer ? footer.offsetHeight : 0

function getPercentageOfFade (trigger) {
    return 100 / transitionDistance * clamp(
        window.innerHeight - trigger.top,
        {min: 0, max: transitionDistance},
    ) / 100
}

function clamp (number, {min = 0, max = 0}) {
    return Math.min(Math.max(number, min), max)
}

function calculateColorBasedOnScrollPosition () {

    // Do we have to fade?
    const fade = getTriggersToTheirPosition().find(isBottomThirdOfScreen)

    if (fade && fade.to) {
        setCurrentColorIndex(fade.index)
        setBackgroundColor(calculateColorValue(fade.from.background, fade.to.background, getPercentageOfFade(fade)))

        // The text should fade a little earlier into the new color so it does not become unreadable.
        const amplifier = 10
        let textPercentage = getPercentageOfFade(fade) * amplifier
        textPercentage = clamp(textPercentage, {min: 0, max: 1})
        setTextColor(calculateColorValue(fade.from.text, fade.to.text, textPercentage))

        return
    }

    // Scrolled past a trigger
    const scrolledPastTrigger = getTriggersToTheirPosition().reduce(isAboveBottomThirdOfScreen, null)

    if (scrolledPastTrigger && scrolledPastTrigger.to) {
        setCurrentColorIndex(scrolledPastTrigger.index)
        setBackgroundColor(scrolledPastTrigger.to.background)
        setTextColor(scrolledPastTrigger.to.text)

        return
    }

    // Neither of the above so probably haven't scrolled yet
    setCurrentColorIndex(0)
    setBackgroundColor(colorPairs[0].background)
    setTextColor(colorPairs[0].text)
}

function setBackgroundColor (color) {
    document.documentElement.style.setProperty(
        '--color-set-background',
        color
    )
}

function setTextColor (color) {
    document.documentElement.style.setProperty(
        '--color-set-text',
        color
    )
}

function setCurrentColorIndex (index) {
    document.documentElement.style.setProperty('--color-index', index)
}

function getCurrentColorIndex () {
    return document.documentElement.style.getPropertyValue('--color-index')
}

window.addEventListener('scroll', calculateColorBasedOnScrollPosition)
window.addEventListener('DOMContentLoaded', calculateColorBasedOnScrollPosition)
