let parallaxImages = []
let windowHeight = 0

document.addEventListener('DOMContentLoaded', function () {
  parallaxImages = document.querySelectorAll('.parallax')
  windowHeight = window.innerHeight

  // Attach the handleParallax function to the scroll and resize events
  window.addEventListener('scroll', handleParallax)
  window.addEventListener('resize', handleResize)
  handleParallax()

})

function handleResize () {
  windowHeight = window.innerHeight
  handleParallax()
}

// Function to handle the parallax effect
function handleParallax () {
  parallaxImages.forEach(img => {
    let parent = img.parentNode
    let rect = parent.getBoundingClientRect()

    if (rect.top <= windowHeight && rect.bottom >= 0) {

      let parentHeight = parent.offsetHeight
      let imgHeight = img.offsetHeight
      let diffHeight = imgHeight - parentHeight
      let visibleHeight = windowHeight + parentHeight
      let stepsPerPixel = diffHeight / visibleHeight

      // Calculate the offset for the parallax effect
      let offset = Math.abs((rect.top - windowHeight) * stepsPerPixel)

      // Apply the offset to the image's top position
      //img.style.transform = `translateY(${offset}px)`
      img.style.bottom = `-${offset}px`

    }
  })
}
