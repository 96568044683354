
document.addEventListener('DOMContentLoaded', function () {
    // Function to update the width of the hr elements
    function updateHrWidth () {
        const buttons = document.querySelectorAll('.button')

        buttons.forEach(button => {
            const div = button.querySelector('div') // Selects the first div in each button
            const divWidth = window.getComputedStyle(div).width
            const hr = button.querySelector('hr')

            hr.style.width = divWidth
        })
    }

    window.addEventListener('DOMContentLoaded', updateHrWidth)
    window.addEventListener('resize', updateHrWidth)

})